import styled, {keyframes} from "styled-components";
import {transparentize} from "polished";
import {hintSkeleton} from "../../styled-mixins/skeleton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputContainer = styled.label<{ $shake?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;

  animation: infinite 200ms ${props => props.$shake && shakeAnimation};
`;

const Input = styled.input<{ $small?: boolean }>`
  font-size: ${props => (props.$small ? 3 : 4)}rem;
  font-weight: 800;
  line-height: 4rem;
  border: 0;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 0;
  font-family: "Nunito", "Helvetica Neue", serif;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  color: ${props => props.theme.text};
`;

const InputCurrency = styled.div<{ $small?: boolean }>`
  margin-left: 8px;
  color: ${props => props.theme.hint};
  font-family: "Nunito", "Helvetica Neue", serif;
  font-size: ${props => (props.$small ? 3 : 4)}rem;
  font-weight: 800;
  line-height: 4rem;
  letter-spacing: -2.56px;
`;

const InputError = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);

  color: ${props => props.theme.error};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 0 4px;
`;

const Output = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px;
  gap: 8px;
  width: fit-content;
`;

const OutputLabel = styled.div`
  color: ${props => props.theme.hint};

  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;

  margin-bottom: 4px;
`;

const OutputBonus = styled.div`
  border-radius: 999px;
  padding: 6px 20px;
  text-align: center;

  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
`;

const OutputBalance = styled(OutputBonus)`
  border: 1px solid #29356f;
`;

const OutputBonusBalance = styled(OutputBonus)`
  padding: 7px 13px;
  font-weight: 600;
  background: linear-gradient(
    92deg,
    #6b40ea 0%,
    #6558ee 19.92%,
    #5c6cf3 39.84%,
    #4f7ff7 59.76%,
    #3a91fb 79.68%,
    #00a3ff 99.6%
  );
`;

const OutputBonusFreespins = styled(OutputBonus)`
  border: 1px solid #6b40ea;

  background: rgba(50, 60, 119, 0.6);

  box-shadow:
    12px 6px 24px -14px rgba(0, 163, 255, 0.6),
    -12px 6px 24px -14px rgba(191, 69, 248, 0.6);
`;

const QuickAmounts = styled.div`
  margin: 12px -4px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
`;

const QuickAmount = styled.button<{ $selected?: boolean }>`
  position: relative;

  border: 0;
  margin: 0;
  background: none;
  cursor: pointer;

  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding: 12px;
  border-radius: 20px;
  background: ${props =>
    props.$selected ? props.theme.button : transparentize(0.9, props.theme.button)};

  backdrop-filter: blur(8px);
`;

const QuickAmountPopular = styled.div`
  position: absolute;
  top: -13px;
  padding: 4px 7px;
  background: ${props => props.theme.button};
  color: ${props => props.theme.buttonText};

  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.625rem;
  text-transform: uppercase;
  border-radius: 36px;
  border: 2px solid ${props => props.theme.bg};
`;

const QuickAmountFiat = styled.div<{ $selected?: boolean }>`
  color: ${props => (props.$selected ? props.theme.buttonText : props.theme.text)};

  font-family: "Nunito", "Helvetica Neue", serif;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5rem;

  span {
    color: ${props => (props.$selected ? props.theme.buttonText : props.theme.hint)};
  }
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;

const InputSkeleton = styled.div`
  ${hintSkeleton};
  height: 100%;
  width: 100px;
`;

export const shakeAnimation = keyframes`
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
`;

const BalanceLabel = styled.div`
  width: fit-content;
  margin: 0 auto 8px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;

  color: ${props => props.theme.link};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export {
  Container,
  InputContainer,
  InputCurrency,
  InputError,
  Input,
  TextSkeleton,
  InputSkeleton,
  Output,
  QuickAmounts,
  QuickAmountFiat,
  QuickAmount,
  QuickAmountPopular,
  OutputLabel,
  OutputBalance,
  OutputBonusBalance,
  OutputBonusFreespins,
  BalanceLabel
};
