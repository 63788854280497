import { useTranslation } from "services/i18n";
import { useState } from "../hooks/use-state";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { Bonus } from "../models";
import { useBalance } from "../services/balance";
import { BuyAmountView } from "./buy/amount";
import { usePromise } from "../hooks/use-promise";
import { reroute } from "./buy/reroute";
import { routes } from "../routes";
import { useApiService } from "../services/api";
import { useNavigate } from "../hooks/use-navigate";
import { useNotificationsService } from "../services/notifications";
import { BuyBankPageSkeleton } from "./buy/bank";
import { BonusStoriesPage } from "./bonus-stories";
import { useCurrenciesService, useOrderCurrency } from "../services/currencies";
import { useOrderService } from "services/order";

import { ReactComponent as DepositCryptoIcon } from "icons/deposit-method-crypto.svg";
import { ReactComponent as DepositCardIcon } from "icons/deposit-method-card.svg";
import { ReactComponent as RightIcon } from "icons/right.svg";
import FirstDepositIcon from "icons/first-deposit-bonus-logo.svg";

import * as S from './first-deposit.styled';

type BonusesViewProps = {
  bonuses: Bonus[] | undefined;
  onContinueWithBonuses(): void;
  onContinueWithoutBonuses(): void;
};

export function BonusesView(props: BonusesViewProps) {
  const t = useTranslation();

  const textRef = useRef<HTMLDivElement>(null);
  const active = useState<Bonus["id"] | null>(null);

  useEffect(() => {
    if (active.get) {
      textRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [active.get]);

  return (
    <S.Container>
      <S.GlobalStyles />
      <S.Header style={{ paddingBottom: 16 }}>
        <S.HeaderIcon src={FirstDepositIcon} alt={"jetton"} />
        <S.HeaderText>{t("ui", "casino_available_bonuses_header")}</S.HeaderText>
      </S.Header>
      <S.BonusesContainer>
        {props.bonuses?.map(bonus => {
          const isActive = active.get === bonus.id;

          return (
            <Fragment key={bonus.id}>
              <S.BonusContainer
                $active={isActive}
                onClick={() => active.set(isActive ? null : bonus.id)}
              >
                <S.BonusTitle>{bonus.title}</S.BonusTitle>
                <S.BonusMore>{t("ui", "bonuses_show_more")}</S.BonusMore>
                <S.BonusExpander $active={isActive} />
              </S.BonusContainer>
              {isActive && (
                <S.BonusText
                  dangerouslySetInnerHTML={{ __html: bonus.description }}
                  ref={textRef}
                />
              )}
            </Fragment>
          );
        })}
      </S.BonusesContainer>
      <S.ContinueButton onClick={props.onContinueWithBonuses}>
        {t("ui", "bonuses_continue")}
      </S.ContinueButton>
      <S.NoBonusesButton onClick={props.onContinueWithoutBonuses}>
        {t("ui", "bonuses_without")}
      </S.NoBonusesButton>
    </S.Container>
  );
}

export type DepositViewProps = {
  onCrypto(): void;
  onFiat(): void;
};

export function DepositView(props: DepositViewProps) {
  const t = useTranslation();
  const api = useApiService();
  const notifications = useNotificationsService();
  const navigate = useNavigate();
  const balance = useBalance();
  const currenciesService = useCurrenciesService();
  const orderService = useOrderService();

  const onCurrencyChange = () => {
    navigate(routes.settings())
  };

  const valueFrom = useState(0);
  const submitting = useState(false);
  const hideBuy = useState(() =>
    balance
      ? !currenciesService.orderCurrencies.value.find(c => c.code === balance.fiat.currency.code)
      : false,
  );

  const makeDirty = useRef<() => void>();

  const order = usePromise(
    () =>
      api.createBuyOrder().then(order => {
        orderService.setNeedRecaptcha(order.needRecaptcha)
        return order;
      }),
    [balance?.fiat.currency.code],
  );
  useEffect(() => {
    if (order.value && order.value.status !== "enter_amount") {
      navigate(reroute(order.value));
    }

    if (order.error) {
      hideBuy.set(true);
    }
  }, [order.value, order.error?.message]);

  const canSubmit =
    !submitting.get &&
    order.value &&
    valueFrom.get >= order.value.minAmount &&
    valueFrom.get <= order.value.maxAmount;

  const presets = useOrderCurrency(order.value?.currencyFrom?.code)?.valuePresets;

  async function handleSubmit() {
    if (
      balance &&
      !currenciesService.orderCurrencies.value.find(c => c.code === balance.fiat.currency.code)
    ) {
      notifications.notify({
        title: t("ui", "error_currency_not_supported_title", {
          currency: balance?.fiat.currency.code,
        }),
        message: t("ui", "error_currency_not_supported_message"),
        buttons: currenciesService.orderCurrencies.value
          .map(c => c.code)
          .map((currency: string) => ({
            label: currency,
            onClick() {
              void api.setActiveCurrency({ primary: currency });
            },
          })),
      });

      return;
    }

    if (!canSubmit) {
      makeDirty.current?.();
      return;
    }

    try {
      submitting.set(true);
      const newOrder = await api.setBuyOrderAmount(
        order.value.id,
        valueFrom.get,
        window.location.origin + routes.buy.status(order.value),
      );

      navigate(reroute(newOrder));
    } catch (err) {
      notifications.notify({
        message: t("errors", "wallet_order_error"),
      });
    } finally {
      submitting.set(false);
    }
  }

  if (submitting.get) {
    return <BuyBankPageSkeleton />;
  }

  return (
    <S.Container style={{ justifyContent: hideBuy.get ? "center" : "flex-start" }}>
      <S.GlobalStyles />

      {!hideBuy.get && (
        <S.BuyContainer>
          <BuyAmountView
            valueFrom={valueFrom.get}
            onFromChange={valueFrom.set}
            valueTo={0}
            bonuses={balance?.availableBonuses}
            order={order.value}
            presets={presets}
            dirtySignal={makeDirty}
            onCurrencyChange={onCurrencyChange}
          />
        </S.BuyContainer>
      )}
      <S.DepositHeader>{t("ui", "casino_new_deposit_header")}</S.DepositHeader>

      <S.Methods>
        <S.Method onClick={handleSubmit}>
          <DepositCardIcon />
          <S.MethodName>{t("ui", "casino_new_deposit_card")}</S.MethodName>
          <RightIcon />
        </S.Method>

        <S.Method onClick={props.onCrypto}>
          <DepositCryptoIcon />
          <S.MethodName>{t("ui", "casino_new_deposit_crypto")}</S.MethodName>
          <RightIcon />
        </S.Method>
      </S.Methods>
    </S.Container>
  );
}

enum Page {
  // Bonuses,
  Deposit,
  Stories,
}

export function FirstDepositView(props: DepositViewProps) {
  const page = useState(Page.Deposit);
  const balance = useBalance();
  const bonuses = balance?.availableBonuses?.filter(_ => _.isFirst);

  useEffect(() => {
    if (bonuses?.length === 0) {
      page.set(Page.Deposit);
    }
  }, [bonuses?.length]);

  // useTelegramBackButton(
  //   page.get === Page.Deposit && (bonuses?.length ?? 0) > 0
  //     ? {
  //       text: t("ui", "back"),
  //       onClick() {
  //         page.set(Page.Bonuses);
  //       },
  //     }
  //     : undefined,
  // );

  const stories = useMemo(() => {
    return <BonusStoriesPage onComplete={() => page.set(Page.Deposit)} />;
  }, []);

  switch (page.get) {
    // case Page.Bonuses:
    //   return (
    //     <BonusesView
    //       // onContinueWithBonuses={() => page.set(Page.Stories)}
    //       onContinueWithBonuses={() => page.set(Page.Deposit)}
    //       onContinueWithoutBonuses={() => page.set(Page.Deposit)}
    //       bonuses={bonuses}
    //     />
    //   );
    case Page.Deposit:
      return <DepositView {...props} />;
    case Page.Stories:
      return stories;
    default:
      return page.get satisfies never;
  }
}
