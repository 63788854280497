import styled, { createGlobalStyle, css, keyframes } from "styled-components";
import { customScrollbars } from "../styled-mixins/custom-scrollbar";
import MethodOverlay from "../icons/deposit-method-overlay.svg";
import { ReactComponent as RightIcon } from "icons/right.svg";

const GlobalStyles = createGlobalStyle`
    body, html {

    }
`;

const Container = styled.div`
  background: linear-gradient(180deg, #18204a 0%, rgba(24, 32, 74, 0.8) 100%),
  url("/new-deposit-background.webp"),
  lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  margin: -16px -20px;
`;

const Header = styled.div`
  padding: 32px;

  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 39.2px */
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const HeaderIcon = styled.img``;
const HeaderText = styled.div``;

const Methods = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
  gap: 12px;
`;

const Method = styled.button`
  margin: 0;
  padding: 16px;
  text-align: left;

  border: 0;
  border-radius: 16px;

  background: linear-gradient(
    92deg,
    #6b40ea 0%,
    #6558ee 19.92%,
    #5c6cf3 39.84%,
    #4f7ff7 59.76%,
    #3a91fb 79.68%,
    #00a3ff 99.6%
  );

  box-shadow:
    0 8px 24px 0 rgba(8, 122, 255, 0.24),
    rgba(255, 255, 255, 0.1) 0 2px 0 0 inset,
    rgba(0, 0, 0, 0.16) 0 -1px 0 0 inset;

  align-items: center;

  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;

  display: flex;
  flex-direction: row;
  gap: 16px;

  position: relative;

  overflow: hidden;

  cursor: pointer;

  filter: brightness(1);
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.2);
  }

  &::after {
    content: "";
    display: block;

    position: absolute;
    right: 60px;
    top: 0;
    bottom: 0;
    width: 300px;
    background: url("${MethodOverlay}") no-repeat center right;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const MethodName = styled.div`
  flex-grow: 1;
`;

const ContinueButton = styled.button`
  border: 0;

  margin: 32px;
  margin-bottom: 0;
  padding: 16px;

  border-radius: 100px;
  background: linear-gradient(84deg, #6b40ea -0.06%, #00a3ff 99.94%);
  box-shadow:
    0 1px 0 0 rgba(255, 255, 255, 0.04) inset,
    0 8px 8px -4px rgba(15, 17, 33, 0.08);

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;

  cursor: pointer;
`;

const NoBonusesButton = styled.div`
  padding: 16px;
  margin-bottom: 16px;

  color: #9ea8dd;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;

  cursor: pointer;

  align-self: center;
`;

const BonusesContainer = styled.div`
  overflow-y: auto;
  padding: 16px;
`;

const BonusContainer = styled.div<{ $active: boolean }>`
  padding: 16px;

  display: grid;
  grid-template:
    "title expand"
    "more expand"
    / 1fr max-content;

  row-gap: 8px;
  align-items: center;

  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background: rgba(101, 88, 238, 0.2);
  }

  ${props =>
    props.$active &&
    css`
      box-shadow: 0 8px 24px 0 rgba(8, 122, 255, 0.24);
      background:
        url("${MethodOverlay}") no-repeat center right,
        linear-gradient(
          92deg,
          #6b40ea 0%,
          #6558ee 19.92%,
          #5c6cf3 39.84%,
          #4f7ff7 59.76%,
          #3a91fb 79.68%,
          #00a3ff 99.6%
        ) !important;
      background-size: contain !important;
    `};
`;

const BonusTitle = styled.div`
  grid-area: title;

  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%; /* 24px */
  letter-spacing: -0.2px;
`;

const BonusMore = styled.div`
  grid-area: more;

  color: rgba(255, 255, 255, 0.64);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.14px;
`;

const BonusExpander = styled(RightIcon)<{ $active: boolean }>`
  grid-area: expand;
  transition: transform 200ms ease-in-out;
  transform: rotate(${props => (props.$active ? -90 : 90)}deg);
`;

const BonusText = styled.div`
  padding: 4px 16px;
  border-radius: 16px;
  background: #323c77;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 5px 0;
  }

  max-height: 180px;
  overflow-y: auto;
  ${customScrollbars};

  animation: 200ms linear ${keyframes`
        from {
            opacity: 0;
            max-height: 0
        }
        to {
            opacity: 1;
            max-height: 180px;
        }
    `};

  scroll-margin-top: 100px;

  font-size: 13px;
`;

const BuyContainer = styled.div`
  padding: 12px;
`;

const DepositHeader = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;

  padding: 16px;
`;

export {
  Container,
  BonusText,
  Header,
  HeaderIcon,
  HeaderText,
  BonusContainer,
  BonusExpander,
  BuyContainer,
  DepositHeader,
  BonusTitle,
  BonusMore,
  BonusesContainer,
  ContinueButton,
  NoBonusesButton,
  Methods,
  Method,
  MethodName,
  GlobalStyles
};
