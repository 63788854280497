import { Bonus, Currency, formatAmount } from "models";
import { routes } from "routes";
import { fiatToCrypto } from "lib/order-conversion";
import { render } from "lib/render";
import { elementFont, textWidth } from "lib/text-width";
import { replicate } from "lib/replicate";
import { reroute } from "./reroute";

import { useNavigate } from "hooks/use-navigate";
import { usePromise } from "hooks/use-promise";
import { BuyOrder } from "shared/models/buy";
import { ApiError, ApiService, isCustomException, useApiService } from "services/api";
import { useState } from "hooks/use-state";
import { useTelegramBackButton, useTelegramMainButton } from "services/telegram";
import { MutableRefObject, useEffect, useLayoutEffect, useRef } from "react";
import { NotificationsService, useNotificationsService } from "services/notifications";
import { Translation, useTranslation } from "services/i18n";
import { useTrack } from "services/track";
import { useBalance } from "services/balance";

import { CentralHeader } from "components/central-header";
import { useOrderCurrencies, useOrderCurrency } from "services/currencies";
import { useOrderService } from "services/order";
import { BuyBankPageSkeleton } from "./bank";

import { ReactComponent as BalanceChangeIcon } from "icons/right2.svg";

import * as S from "./amount.styled";

const validOrderStatuses = ["enter_amount"] as const satisfies readonly BuyOrder["status"][];

export function validateOrderCreationError(
  currencies: Currency[],
  error: Error,
  notifications: NotificationsService,
  api: ApiService,
  t: Translation,
) {
  const isInvalidCurrency = error instanceof ApiError && error.response?.status === 409;

  if (isInvalidCurrency) {
    error.response?.json().then(response => {
      const payload: { currency: string; currencies: string[] } = response.payload;
      notifications.notify({
        title: t("ui", "error_currency_not_supported_title", {
          currency: payload.currency,
        }),
        message: t("ui", "error_currency_not_supported_message"),
        buttons: payload.currencies
          .filter(c => currencies.some(_ => _.code === c))
          .map((currency: string) => ({
            label: currency,
            onClick() {
              void api.setActiveCurrency({ primary: currency });
            },
          })),
      });
    });
  }

  if (!isCustomException(error)) {
    notifications.notify({
      message: t("errors", "wallet_order_error"),
    });
  }
}

export function BuyAmountPage() {
  const t = useTranslation();
  const track = useTrack();
  const api = useApiService();
  const navigate = useNavigate();
  const notifications = useNotificationsService();
  const balance = useBalance();
  const currencies = useOrderCurrencies();
  const orderService = useOrderService();

  const submitting = useState(false);
  const valueFrom = useState(0);

  const order = usePromise(
    () =>
      api.createBuyOrder().then(order => {
        orderService.setNeedRecaptcha(order.needRecaptcha);
        return order;
      }),
    [],
  );
  useEffect(() => {
    if (order.value && order.value.status !== "enter_amount") {
      navigate(reroute(order.value));
      return;
    }

    if (order.error) {
      validateOrderCreationError(currencies, order.error, notifications, api, t);
      navigate(routes.main());
      return;
    }

    if (order.value && order.value.minAmount >= order.value.maxAmount) {
      notifications.notify({
        message: "Method is currently unavailable, please try again later",
      });
      navigate(routes.main());
      return;
    }
  }, [order]);

  const valueTo = order.value ? fiatToCrypto(order.value, valueFrom.get) : 0;

  const presets = useOrderCurrency(order.value?.currencyFrom?.code)?.valuePresets;

  const canSubmit =
    !submitting.get &&
    order.value &&
    valueFrom.get >= order.value.minAmount &&
    valueFrom.get <= order.value.maxAmount;

  const onCurrencyChange = () => navigate(routes.settings());

  async function handleSubmit() {
    if (!canSubmit) return;

    try {
      submitting.set(true);
      const newOrder = await api.setBuyOrderAmount(
        order.value.id,
        valueFrom.get,
        window.location.origin + routes.buy.status(order.value),
      );
      track("[Wallet] Buy: amount provided", {
        currency: order.value.currencyFrom.code,
        fiat: valueFrom.get,
        jetton: valueTo,
      });

      navigate(reroute(newOrder));
    } catch (err) {
      notifications.notify({
        message: t("errors", "wallet_order_error"),
      });
    } finally {
      submitting.set(false);
    }
  }

  useTelegramBackButton({
    text: t("ui", "wallet_back_button_currency_selection"),
    onClick() {
      track("[Wallet] back to main page");
      navigate(routes.main());
    },
  });

  useTelegramMainButton({
    text: order.value
      ? t("ui", "wallet_buy_deposit_on", {
          amount: formatAmount(valueFrom.get, order.value.currencyFrom, true),
        })
      : "...",
    shown: canSubmit,
    progress: submitting.get,
    onClick: handleSubmit,
  });

  if (submitting.get) {
    return <BuyBankPageSkeleton />;
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();

        handleSubmit();
      }}
    >
      <BuyAmountView
        bonuses={balance?.availableBonuses}
        valueFrom={valueFrom.get}
        onFromChange={valueFrom.set}
        valueTo={valueTo}
        presets={presets}
        order={order.value}
        onCurrencyChange={onCurrencyChange}
      />
    </form>
  );
}

type Props = {
  valueFrom: number;
  valueTo: number;
  onFromChange(value: number): void;

  bonuses: Bonus[] | undefined;
  presets: number[] | undefined;
  order: (BuyOrder & { status: (typeof validOrderStatuses)[number] }) | undefined;
  onCurrencyChange?: () => void;

  // для того чтобы можно было извне сказать, что воспринимайте инпут грязным
  dirtySignal?: MutableRefObject<undefined | (() => void)>;
};

export function BuyAmountView({ order, ...props }: Props) {
  const t = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const inputDirty = useState(false);
  const inputShake = useState(false);
  const isLoading = useState(true);
  const startTime = useState(0);

  useEffect(() => {
    if (!order) {
      startTime.set(Date.now());
    }
  }, []);

  useEffect(() => {
    if (order) {
      const loadingTime = Date.now() - startTime?.get;
      if (loadingTime < 2000) {
        const timeout = setTimeout(() => {
          isLoading.set(false);
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      } else {
        isLoading.set(false);
      }
    }
  }, [order]);

  useEffect(() => {
    if (props.dirtySignal) {
      let timeout: NodeJS.Timeout;
      props.dirtySignal.current = () => {
        inputDirty.set(true);
        clearTimeout(timeout);
        inputShake.set(true);
        timeout = setTimeout(() => {
          inputShake.set(false);
          clearTimeout(timeout);
        }, 300);
      };
    }
  }, [props.dirtySignal]);

  const availableBonuses =
    props.bonuses?.filter(
      bonus =>
        bonus.isCurrent &&
        props.valueFrom >= (bonus.minAmount ?? Number.MIN_VALUE) &&
        props.valueFrom < (bonus.maxAmount ?? Number.MAX_VALUE),
    ) ?? [];

  const bonusValue = availableBonuses
    .map(bonus => bonus.amountMultiplier * props.valueFrom)
    .reduce((a, b) => a + b, 0);

  const freespins = availableBonuses
    .map(bonus => bonus.freespins)
    .filter((x: number | null): x is number => x !== null)
    .reduce((a, b) => a + b, 0);

  function relayout() {
    if (inputRef.current) {
      const text = (inputRef.current.value || 0).toString();
      inputRef.current.style.maxWidth = textWidth(text, elementFont(inputRef.current)) + "px";
    }
  }

  useEffect(() => {
    document.fonts.ready.then(relayout);
  }, [inputRef.current]);

  useLayoutEffect(relayout, [props.valueFrom, inputRef.current, order?.currencyFrom.displayFormat]);

  const inputShouldBeSmall = props.valueFrom.toString().length > 6;

  return (
    <S.Container>
      <CentralHeader>
        {props.bonuses === undefined ? (
          <S.TextSkeleton as={"div"}>Enter deposit amount:</S.TextSkeleton>
        ) : props.bonuses.length === 0 ? (
          t("ui", "wallet_buy_deposit_amount")
        ) : (
          t("ui", "wallet_buy_deposit_amount_bonus")
        )}
      </CentralHeader>
      <S.BalanceLabel onClick={props.onCurrencyChange}>
        {t("ui", "wallet_currency_edit")}
        <BalanceChangeIcon />
      </S.BalanceLabel>
      {order ? (
        <S.InputContainer $shake={inputShake.get}>
          <S.Input
            $small={inputShouldBeSmall}
            ref={inputRef}
            inputMode="numeric"
            placeholder="0"
            value={props.valueFrom === 0 ? "" : props.valueFrom}
            onChange={event => {
              inputDirty.set(true);
              const value = parseInt(event.currentTarget.value);

              if (Number.isNaN(value)) {
                props.onFromChange(0);
              } else if (event.currentTarget.value.length <= 7) {
                props.onFromChange(value);
              }
            }}
          />
          <S.InputCurrency $small={inputShouldBeSmall}>
            {render(order.currencyFrom.displayFormat, { amount: "" })}
          </S.InputCurrency>
          {(inputDirty.get || props.valueFrom !== 0) && (
            <S.InputError>
              {props.valueFrom < order.minAmount ? (
                <>
                  {t("ui", "wallet_input_min")}{" "}
                  {formatAmount(order.minAmount, order.currencyFrom, true)}
                </>
              ) : props.valueFrom > order.maxAmount ? (
                <>
                  {t("ui", "wallet_input_max")}{" "}
                  {formatAmount(order.maxAmount, order.currencyFrom, true)}
                </>
              ) : undefined}
            </S.InputError>
          )}
        </S.InputContainer>
      ) : (
        <S.InputContainer>
          <S.InputSkeleton />
        </S.InputContainer>
      )}
      {(bonusValue > 0 || freespins > 0 || props.valueFrom > 0) && (
        <S.Output>
          <S.OutputLabel>{t("ui", "wallet_buy_will_receive")}</S.OutputLabel>

          {props.valueFrom > 0 && (
            <S.OutputBalance>
              {order && formatAmount(props.valueFrom, order.currencyFrom)}
            </S.OutputBalance>
          )}

          {order && bonusValue > 0 && (
            <S.OutputBonusBalance>
              {t("ui", "bonus")} {order && formatAmount(bonusValue, order.currencyFrom)}
            </S.OutputBonusBalance>
          )}

          {order && freespins > 0 && (
            <S.OutputBonusFreespins>
              + {freespins} {t("ui", "freespins")}
            </S.OutputBonusFreespins>
          )}
        </S.Output>
      )}
      {props.presets === undefined || order === undefined || isLoading.get ? (
        <S.QuickAmounts>
          {replicate(6).map(i => (
            <S.QuickAmount key={i}>
              <S.QuickAmountFiat>
                <S.TextSkeleton>1000 XX</S.TextSkeleton>
              </S.QuickAmountFiat>
            </S.QuickAmount>
          ))}
        </S.QuickAmounts>
      ) : (
        <S.QuickAmounts>
          {props.presets.map(Number).map((amount, i) => (
            <S.QuickAmount
              key={amount}
              $selected={amount === props.valueFrom}
              type="button"
              onClick={() => props.onFromChange(amount)}
            >
              {i === 2 && (
                <S.QuickAmountPopular>{t("ui", "wallet_buy_deposit_popular")}</S.QuickAmountPopular>
              )}
              <S.QuickAmountFiat $selected={amount === props.valueFrom}>
                {amount} <span>{render(order.currencyFrom.displayFormat, { amount: "" })}</span>
              </S.QuickAmountFiat>
            </S.QuickAmount>
          ))}
        </S.QuickAmounts>
      )}
    </S.Container>
  );
}

export function BuyAmountPageSkeleton() {
  return (
    <BuyAmountView
      valueFrom={0}
      valueTo={0}
      onFromChange={() => {}}
      bonuses={undefined}
      presets={undefined}
      order={undefined}
    />
  );
}
