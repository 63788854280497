enum UserDevice {
  unknown = 0,
  desktop = 1,
  mobile = 2,
}

export function detectUserDevice(): UserDevice {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  const isTouchDevice = "maxTouchPoints" in navigator && navigator.maxTouchPoints > 0;
  const isSmallScreen = window.innerWidth <= 768;

  // Проверяем по userAgent
  const isMobileAgent =
    /android|iphone|ipad|ipod|blackberry|windows phone|opera mini|iemobile|mobile/i.test(userAgent);

  // Если есть явные признаки мобильного устройства
  if (isMobileAgent || (isTouchDevice && isSmallScreen)) {
    return UserDevice.mobile;
  }

  // Если нет признаков мобильного устройства, но есть крупный экран
  if (!isMobileAgent && !isSmallScreen && !isTouchDevice) {
    return UserDevice.desktop;
  }

  // Если определить устройство точно нельзя
  return UserDevice.unknown;
}
