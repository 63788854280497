import * as t from "io-ts";
import { render } from "lib/render";

const optional = <T extends t.Mixed>(ty: T) => t.union([t.null, ty]);

export type Currency = t.TypeOf<typeof Currency>;
export const Currency = t.intersection(
  [
    t.type({
      code: t.string,
      name: t.string,
      icon: t.string,
      withMemo: t.union([t.boolean, t.undefined]),

      precision: t.number,
      addressFormat: t.string,
      displayFormat: t.string,
      displayCode: optional(t.string),

      minWithdrawAmount: t.string,

      isFiat: t.boolean,
      network: t.string,

      withdrawAllowed: optional(t.boolean),
    }),
    t.partial({
      depositBonus: optional(t.number),
    }),
  ],
  "Currency",
);

export type Amount = t.TypeOf<typeof Amount>;
export const Amount = t.type(
  {
    currency: Currency,
    value: t.string,
    primaryValue: t.string,
  },
  "Amount",
);

export type Bonus = t.TypeOf<typeof Bonus>;
export const Bonus = t.type({
  id: t.number,
  title: t.string,
  face: t.string,
  description: t.string,
  isFirst: t.boolean,
  isCurrent: t.boolean,
  minAmount: optional(t.number),
  maxAmount: optional(t.number),
  amountMultiplier: t.number,
  freespins: optional(t.number),
});

export type Balance = t.TypeOf<typeof Balance>;
export const Balance = t.type(
  {
    fiat: Amount,
    crypto: Amount,
    empty: t.boolean,
    availableBonuses: t.array(Bonus),
  },
  "Balance",
);

const BaseTransaction = t.intersection([
  t.type(
    {
      id: t.string,
      amount: Amount,
      timestamp: t.number,
    },
    "BaseTransaction",
  ),
  t.partial({
    showWaiting: optional(t.boolean),
    cancellableUntil: optional(t.union([t.number, t.string])),
  }),
]);

export type CryptoTransaction = t.TypeOf<typeof CryptoTransaction>;
export const CryptoTransaction = t.intersection(
  [
    BaseTransaction,
    t.readonly(
      t.type({
        type: t.union([t.literal("withdraw"), t.literal("deposit")]),
        address: optional(t.string),
        status: t.union([
          t.literal("pending"),
          t.literal("completed"),
          t.literal("declined"),
          t.literal("refund"),
        ]),
        url: optional(t.string),
        message: optional(t.string),
      }),
    ),
  ],
  "CryptoTransaction",
);

export type SellPaymentDetails = t.TypeOf<typeof SellPaymentDetails>;

export const SellPaymentDetails = t.type({
  card: t.union([t.null, t.string]),
  phone: t.union([t.null, t.string]),
  name: t.union([t.null, t.string]),
});

export type BuyPaymentDetails = t.TypeOf<typeof BuyPaymentDetails>;
export const BuyPaymentDetails = t.type({
  card: t.union([t.null, t.string]),
  phone: t.union([t.null, t.string]),
  name: t.union([t.null, t.string]),
});

export function hasPaymentDetails(details: BuyPaymentDetails): boolean {
  return details !== null && (details.card !== null || details.phone !== null);
}

export function matchPaymentDetails<R>(
  details: BuyPaymentDetails,
  handlers: {
    card(value: string): R;
    phone(value: string): R;
  },
): R | undefined {
  if (!!details.card) return handlers.card(details.card);
  if (!!details.phone) return handlers.phone(details.phone);
}

export type OrderTransaction = t.TypeOf<typeof OrderTransaction>;
export const OrderTransaction = t.intersection(
  [
    BaseTransaction,
    t.union([
      t.type(
        {
          type: t.literal("sell"),
          status: t.union([
            //
            t.literal("pending"),
            t.literal("completed"),
            t.literal("declined"),
          ]),
          paymentDetails: SellPaymentDetails,
        },
        "SellOrderTransaction",
      ),
      t.type(
        {
          type: t.literal("buy"),
          status: t.union([
            t.literal("waiting"),
            t.literal("pending"),
            t.literal("completed"),
            t.literal("timeouted"),
            t.literal("declined"),
            t.literal("disputed"),
          ]),
          paymentDetails: BuyPaymentDetails,
        },
        "BuyOrderTransaction",
      ),
    ]),
  ],
  "OrderTransaction",
);

export type Transaction = t.TypeOf<typeof Transaction>;
export const Transaction = t.union([OrderTransaction, CryptoTransaction], "Transaction");

export enum BankMethod {
  CrossBorder = "cross_border",
  sbp = "sbp",
  p2p = "p2p",
  sberpay = "sberpay",
  SberankClick = "sberbank_click",
  TinkoffClick = "tinkoff_click",
  AccountNumber = "account_number",
  AccountNumberSber = "account_number_sber",
}

export type Bank = {
  id: string | number;
  icon: string;
  name: string;
  formBackgroundColor: string;
  subbanks?: Bank[] | null;
  subbank?: Bank[] | null;
  method: BankMethod;
};

export function formatAmount(
  value: string | number,
  currency: Currency,
  noFrac: boolean = false,
): string {
  const amount = Number(value)
    .toFixed(noFrac ? 0 : currency.precision) //
    .replace(/\.0*$/, ""); //

  const [dec, frac] = amount.split(".");
  const decChars = Array.from(dec);
  const decCharsSpaced: string[] = [];

  for (let i = 0; i < decChars.length; i++) {
    decCharsSpaced.push(decChars[i]);
    if ((decChars.length - i - 1) % 3 === 0) {
      decCharsSpaced.push(" ");
    }
  }

  const decSpaced = decCharsSpaced.join("").trim();
  const amountSpaced = decSpaced + (frac ? "." + frac : "");

  return render(currency.displayFormat, { amount: amountSpaced });
}
